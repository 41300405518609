import styled from 'styled-components'
import {palette} from 'styled-tools'

import settings from '@festi/common/constants/settings'

const newDesign = settings.redesign

const TermsLink = styled.a`
  font-size: 1rem;
  font-weight: 500;
  color: ${newDesign ? palette('link') : palette('lightBlue')};
  border-bottom: ${newDesign ? '1px' : '2px'} solid
    ${newDesign ? palette('link') : palette('lightBlue')};
  padding: 0;
  background: none;
  transition: color 0.15s, border-color 0.15s;

  &:hover {
    color: ${palette('lightBlue60')};
    border-color: ${palette('lightBlue60')};
  }
`

export default TermsLink
