import React from 'react'

import {useField, FieldConfig} from 'formik'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'

import {P} from '@festi/common/components/typography'
import {styledTheme} from '@festi/common/themes'
import settings from '@festi/common/constants/settings'

import {IconBox, ShimmerLoader} from '../common'

const newDesign = settings.redesign

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  description?: string
  icon?: RemixiconReactIconComponentType
  loading?: boolean
}

interface LabelProps {
  disabled?: boolean
  noValueBox?: boolean
}

const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: ${ifProp({center: true}, 'center', 'flex-end')};
  position: relative;
  cursor: ${ifProp({disabled: true}, 'not-allowed', 'pointer')};
  opacity: ${ifProp({disabled: true}, '0.4', '1')};
  width: ${ifProp({noValueBox: true}, 'unset', '100%')};
  margin-bottom: 16px;
`

const Text = styled.div`
  font-weight: 500;
  font-size: 1rem;
  color: ${palette('blue')};
  line-height: 42px;
`

const Circle = styled.div`
  position: relative;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  border: 1px solid ${palette('ui20Solid')};
  border-radius: 12px;
  box-sizing: border-box;
  background-color: transparent;
  margin-right: 16px;
  margin-top: 24px;
  transition: border-color 0.15s;

  &:before {
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: ${palette('lightBlue')};
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  ${media.sm`
    margin-right: 28px;
  `}
`

const ValueBox = styled.div`
  position: relative;
  min-height: 72px;
  border: 1px solid ${palette('ui20Solid')};
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 14px;
  flex-grow: 1;
  transition: border-color 0.15s;
  align-items: flex-start;
  border-radius: ${newDesign ? '12px' : '0'};

  ${media.sm`
    padding: 16px;
  `}
`

const ValueBoxText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const IconContainer = styled.div`
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette('light')};
  margin-right: 24px;
`

const RadioInput = styled.input`
  visibility: hidden;
  position: absolute;
  border-radius: 0;

  &:checked ~ ${Circle} {
    border-color: ${palette('lightBlue')};

    &:before {
      transform: scale(1);
    }
  }

  &:checked ~ ${ValueBox} {
    border-color: ${palette('lightBlue')};
    border-width: ${newDesign ? '2px' : '1px'};

    .remixicon-icon {
      fill: ${newDesign ? palette('ui40Solid') : palette('lightBlue')};
    }
  }
`

const LoadingWrapper = styled.div`
  ${ShimmerLoader} {
    margin-bottom: 12px;
  }
`

export default function LargeRadio({
  label,
  description,
  icon: Icon,
  disabled,
  loading = false,
  ...props
}: Props): JSX.Element {
  const displayValueBox = !!label && !!Icon

  return (
    <Label disabled={disabled} noValueBox={!displayValueBox}>
      <RadioInput type="radio" disabled={disabled} {...props} />
      {displayValueBox && (
        <ValueBox>
          {!!Icon &&
            (newDesign ? (
              <IconBox icon={Icon} />
            ) : (
              <IconContainer>
                <Icon size={22} color={styledTheme.palette.ui20Solid} />
              </IconContainer>
            ))}
          <ValueBoxText>
            {!!label && <Text>{label}</Text>}
            {loading ? (
              <LoadingWrapper>
                <ShimmerLoader width={80} />
                <ShimmerLoader width={60} />
              </LoadingWrapper>
            ) : (
              <P>{!!description && description}</P>
            )}
          </ValueBoxText>
        </ValueBox>
      )}
      {!newDesign && <Circle />}
    </Label>
  )
}

export function FormikLargeRadio(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: Props & React.InputHTMLAttributes<HTMLInputElement> & FieldConfig<any>,
): JSX.Element {
  const [field] = useField(props)

  return <LargeRadio {...field} {...props} />
}
