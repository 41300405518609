import React from 'react'

import cn from 'classnames'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {Expandable} from '@festi/common/components/common'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'

const newDesign = settings.redesign

const Card = styled.div`
  position: relative;
  width: 100%;
  ${fluidRange('margin-bottom', '16px', '24px')}
  border: 1px dashed ${palette('ui20Solid')};
  background-color: ${newDesign ? palette('white') : palette('light')};
  transition: border-color 0.15s, background-color 0.15s;
  box-sizing: border-box;
  border-radius: ${newDesign ? '12px' : '0'};

  &.isOpen {
    border-color: ${palette('lightBlue')};
    background-color: ${palette('white')};
  }
`

const ToggleBtn = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 24px;
  border: 0;
  background: none;
  cursor: pointer;
  padding-left: ${newDesign ? '36px' : '24px'};
  ${newDesign
    ? fluidRange('padding-left', '28px', '36px')
    : 'padding-left: 24px;'};

  .remixicon-icon {
    ${newDesign
      ? fluidRange('margin-right', '32px', '36px')
      : 'margin-right: 16px;'};
    transition: transform 0.15s ease-out;
  }
`

const BtnLabel = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${palette('blue')};
`

const FormContainer = styled.div`
  ${fluidRange('padding', '16px', '24px')}
  padding-top: 8px !important;
`

interface Props {
  label: string
  isOpen: boolean
  children: React.ReactNode
  onToggle: () => void
}

export default function ExpandableFormWrapper({
  label,
  isOpen,
  children,
  onToggle,
}: Props): JSX.Element {
  return (
    <Card className={cn({isOpen})}>
      <ToggleBtn type="button" onClick={onToggle}>
        <AddLineIcon
          style={{transform: isOpen ? 'rotate(45deg)' : ''}}
          size={newDesign ? 24 : 32}
          color={
            newDesign
              ? styledTheme.palette.ui40Solid
              : styledTheme.palette.lightBlue
          }
        />
        <BtnLabel>{label}</BtnLabel>
      </ToggleBtn>
      <Expandable expanded={isOpen}>
        <FormContainer>{children}</FormContainer>
      </Expandable>
    </Card>
  )
}
