import classNames from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {CategoryTreeItem} from '@festi/common/utils/categories'

import Link from 'next/link'

export const Item = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 1.125rem;
  font-weight: 500;
  height: 60px;
  padding: 0 2cqw;
  color: ${palette('blue')};
  border: 0 solid transparent;
  border-left: 6px solid transparent;
  background-color: transparent;
  transition: all 0.15s ease-out;
  width: 100%;
  cursor: pointer;

  &:hover,
  &.selected {
    border-color: ${palette('green')};
    background-color: ${palette('ui5Solid')};

    .remixicon-icon {
      fill: ${palette('ui60Solid')};
    }
  }

  .remixicon-icon {
    fill: ${palette('ui30Solid')};
  }
`

const Border = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid ${palette('border')};
  text-align: left;

  .remixicon-icon {
    margin-left: auto;
  }
`

interface ItemProp {
  category: CategoryTreeItem
  selected?: boolean
  onClick?: () => void
  handleClose: () => void
}

export function CategoryItem({
  category,
  selected,
  onClick,
  handleClose,
}: ItemProp) {
  if (!category.children?.length) {
    return (
      <Link href={`/voruflokkar/${category.slug}`} onClick={handleClose}>
        <Item className={classNames({selected})}>
          <Border>{category.name}</Border>
        </Item>
      </Link>
    )
  }
  return (
    <Item className={classNames({selected})} onClick={onClick}>
      <Border>
        <span>{category.name}</span>
        <ArrowRightSLineIcon />
      </Border>
    </Item>
  )
}
