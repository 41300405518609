import cn from 'classnames'
import range from 'lodash/range'
import Link from 'next/link'
import {useRouter} from 'next/router'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled from 'styled-components'

import {styledTheme} from '@festi/common/themes'

interface PagerProps {
  page?: number
  maxPage?: number
  pageMargin?: number
}

const Root = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FlipLink = styled(Link)`
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.15s;

  &.left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &.right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${styledTheme.palette.ui5Solid};
  }
`

const PageLink = styled(Link)`
  background-color: ${styledTheme.palette.white};
  border-radius: 0;
  border-block: 1px solid ${styledTheme.palette.ui20Solid};
  border-left: 1px solid ${styledTheme.palette.ui20Solid};
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${styledTheme.palette.blue};
  font-weight: 500;
  transition: background-color 0.15s;

  &.active {
    background-color: ${styledTheme.palette.ui10Solid};
    color: ${styledTheme.palette.ui60Solid};
  }
  &.minEdge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &.maxEdge {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &.rightBorder {
    border-right: 1px solid ${styledTheme.palette.ui20Solid};
  }

  &:hover {
    background-color: ${styledTheme.palette.ui5Solid};
  }
`

const Spacer = styled.div`
  width: 10px;
  height: 20px;
  border-block: 1px solid ${styledTheme.palette.ui20Solid};
  background-color: ${styledTheme.palette.white};
`

// Note(Bjorg): The redesign is missing some aspects like hover and disabled states and spacer when page count is high.
// I implemented the missing parts in similar way as the old design.
export default function PaginationMenu({
  page,
  maxPage,
  pageMargin = 2,
}: PagerProps): JSX.Element {
  const router = useRouter()

  const hasLess = page > 1
  const hasMore = page < maxPage

  const showMinEdge = page > pageMargin + 1
  const showMaxEdge = page < maxPage - pageMargin

  const min = Math.max(page - pageMargin, 1)
  const max = Math.min(page + pageMargin, maxPage)

  const linkProps = (p: number) => ({
    href: {
      pathname: router.pathname,
      query: {...router.query, page: p},
    },
    shallow: true,
  })

  return (
    <Root>
      {hasLess && (
        <FlipLink {...linkProps(page - 1)} className={cn({left: true})}>
          <ArrowLeftSLineIcon size={20} color={styledTheme.palette.blue} />
        </FlipLink>
      )}
      {showMinEdge && (
        <>
          <PageLink
            {...linkProps(1)}
            className={cn({minEdge: true, rightBorder: true})}
          >
            1
          </PageLink>
          <Spacer />
        </>
      )}
      {range(min, max + 1).map((p: number, i) => (
        <PageLink
          key={p}
          {...linkProps(p)}
          className={cn({
            active: p === page,
            minEdge: !showMinEdge && i === 0,
            maxEdge: !showMaxEdge && i === max - min,
            rightBorder: i === max - min,
          })}
        >
          {p}
        </PageLink>
      ))}
      {showMaxEdge && (
        <>
          <Spacer />
          <PageLink
            {...linkProps(maxPage)}
            className={cn({maxEdge: true, rightBorder: true})}
          >
            {maxPage}
          </PageLink>
        </>
      )}
      {hasMore && (
        <FlipLink {...linkProps(page + 1)} className={cn({right: true})}>
          <ArrowRightSLineIcon size={20} color={styledTheme.palette.blue} />
        </FlipLink>
      )}
    </Root>
  )
}
