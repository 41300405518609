import React from 'react'

import styled from 'styled-components'
import {palette} from 'styled-tools'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {media} from 'styled-bootstrap-grid'
import InformationLineIcon from 'remixicon-react/InformationLineIcon'

import {styledTheme} from '@festi/common/themes'

const CustomTooltip = styled(ReactTooltip)`
  color: ${palette('white')} !important;
  padding: 0 !important;
  background-color: ${palette('black')} !important;
  pointer-events: auto !important;

  .react-tooltip-arrow {
    display: none !important;
  }
`

const Wrapper = styled.div`
  position: relative;
`

const BoxContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 340px;
  white-space: pre-wrap;
  opacity: 1;
  z-index: 1;
  margin: auto;
  padding: 8px;
  border-radius: 4px;
  background-color: ${palette('black')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  ${media.xs`
    width: 100vw;
  `}
`

const Children = styled.div`
  cursor: pointer;
`

interface BoxProps {
  id: string
  content?: React.ReactNode
  children: React.ReactNode
  disableHover?: boolean
  margin?: boolean
  afterShow?: () => void
}

export function Tooltip({id, content, children, margin, afterShow}: BoxProps) {
  if (!content) return <div>{children}</div>

  return (
    <>
      <CustomTooltip
        id={`tooltip-${id}`}
        opacity={1}
        offset={4}
        style={{zIndex: 1}}
        delayHide={300}
        clickable
        afterShow={afterShow}
      >
        <BoxContent>{content}</BoxContent>
      </CustomTooltip>

      <Wrapper
        data-tip
        data-tooltip-id={`tooltip-${id}`}
        style={{margin: margin ? '0 6px' : '0'}}
      >
        <Children>{children}</Children>
      </Wrapper>
    </>
  )
}

interface Props {
  id: string
  small?: boolean
  children: React.ReactNode
}

export function InfoTooltip({id, small = false, children}: Props) {
  return (
    <Tooltip id={id} content={children} margin>
      <InformationLineIcon
        size={small ? 18 : 20}
        color={styledTheme.palette.ui50Solid}
      />
    </Tooltip>
  )
}
