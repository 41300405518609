import React from 'react'

import Link from 'next/link'
import {media} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {ifProp, palette} from 'styled-tools'

import {DynamicIcon} from '@festi/common/components/common'
import {PSmall} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'

const newDesign = settings.redesign

interface Props {
  icon: string
  title: string
  description: string
  link?: string
  small?: boolean
  border?: boolean
  darkMode?: boolean
}

interface CardProps {
  small: boolean
  border: boolean
  darkMode: boolean
}

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 16px;
`

const borderLeftCSS = css`
  border-left: 4px solid ${palette('lightBlue')};
`

const Title = styled.span`
  display: block;
  color: ${palette('blue')};
  font-size: 1.125rem;
  font-weight: 500;
  ${fluidRange('margin-bottom', '8px', '16px')}
`

const Line = styled.span`
  display: block;
  color: ${palette('blue')};
  font-size: 1rem;
  ${fluidRange('margin-bottom', '5px', '8px')}
`

const Card = styled.div<CardProps>`
  background-color: ${ifProp(
    {darkMode: true},
    palette('ui90'),
    palette('white'),
  )};
  border: 1px solid ${palette('ui20Solid')};
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 30px 24px;
  border-radius: ${newDesign ? '12px' : 'unset'};

  ${(props) => props.border && borderLeftCSS}

  ${media.md`
    flex-direction: column;
  `}

  ${Title},
  ${Line} {
    color: ${ifProp({darkMode: true}, palette('white'), palette('blue'))};
  }
`

const IconWrapper = styled.div`
  margin-bottom: 15px;
  margin-right: 15px;

  svg {
    display: block;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

function InfoCardInner({
  icon,
  title,
  description,
  small = false,
  border = false,
  darkMode = false,
}: Props): JSX.Element {
  return (
    <Card small={small} border={border} darkMode={darkMode}>
      <IconWrapper>
        <DynamicIcon icon={icon} size={24} />
      </IconWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <PSmall darkMode={darkMode}>{description}</PSmall>
      </ContentWrapper>
    </Card>
  )
}

export default function InfoCard(props: Props): JSX.Element {
  return (
    <CardWrapper>
      {props.link ? (
        <Link href={props.link} passHref prefetch={false}>
          <InfoCardInner {...props} />
        </Link>
      ) : (
        <InfoCardInner {...props} />
      )}
    </CardWrapper>
  )
}
