import {useEffect, useRef, useState} from 'react'

import Image from 'next/image'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {useUpdateEffect, useWindowSize} from 'react-use'
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from 'react-zoom-pan-pinch'

import {Coordinates} from '@festi/common/api/rest'

import PricerPin from './PricerPin'

const Root = styled.div`
  width: 100%;
  min-height: 75vh;

  .react-transform-wrapper {
    width: 100%;
    min-height: 75vh;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    > div {
      width: 100%;
      padding: 20px;
      margin-bottom: 60px;
      border-radius: 20px;
    }
  }
  ${media.md`
    padding-bottom: 0;

    .react-transform-wrapper {
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 20px;
    }
  `}
`

interface MapProps {
  width: number
  height: number
}

const Map = styled.div<MapProps>`
  position: relative;
  height: ${(p) => (p.height ? `${p.height}px` : '800px')};
  margin: 0 auto;

  img {
    width: ${(p) => (p.width ? `${p.width}px` : '425px')};
    height: ${(p) => (p.height ? `${p.height}px` : '800px')};
  }
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: white;
  border-radius: 8px;
`

export const storeInfo = {
  skeifan: {image: '/images/skeifan-store1.png', storeId: 1861},
  grandi: {image: '/images/grandi-store.png', storeId: 2443},
  lindir: {image: '/images/lindir-store.png', storeId: 2548},
  kringlan: {image: '/images/kringlan-store.png', storeId: 1861},
  akureyri: {image: '/images/akureyri-store.png', storeId: 1861},
}

const mapSizes = {
  skeifan: {
    width: 1600,
    height: 3000,
  },
  grandi: {
    width: 1700,
    height: 2900,
  },
  lindir: {
    width: 2300,
    height: 2140,
  },
  kringlan: {
    width: 4000,
    height: 6823,
  },
  akureyri: {
    width: 4000,
    height: 6800,
  },
}

const PADDING = 40

function ResetSize({selected}: {selected: string}) {
  const {resetTransform} = useControls()

  useUpdateEffect(() => {
    resetTransform()
  }, [selected])

  return null
}

interface Props {
  pulse: boolean
  selected: string
  locations: Coordinates[]
  buttons?: React.ReactNode
}

export default function PricerMap({
  pulse,
  selected,
  locations,
  buttons,
}: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const {height} = useWindowSize()

  const [maxWidth, setMaxWidth] = useState(
    (ref?.current?.clientWidth || 1000) - PADDING,
  )
  const maxHeight = 1000

  const selectedImage = storeInfo[selected].image

  const baseWidth = mapSizes[selected].width
  const baseHeight = mapSizes[selected].height

  const calculatedHeight = (height > maxHeight ? maxHeight : height) - 240
  const calculatedWidth = baseWidth / (baseHeight / calculatedHeight)

  const isOverMaxWidth = maxWidth < calculatedWidth

  const usedWidth = isOverMaxWidth ? maxWidth : calculatedWidth
  const usedHeight = isOverMaxWidth
    ? (maxWidth / calculatedWidth) * calculatedHeight
    : calculatedHeight

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setMaxWidth(ref.current.clientWidth - PADDING)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return (
    <Root ref={ref}>
      <TransformWrapper
        pinch={{step: 5, excluded: ['pinchDisabled']}}
        wheel={{step: 5, excluded: ['pinchDisabled']}}
        panning={{excluded: ['panningDisabled']}}
        doubleClick={{step: 5, mode: 'toggle'}}
      >
        <TransformComponent>
          <Map width={usedWidth} height={usedHeight}>
            <Image
              src={selectedImage}
              alt={`Map for ${selected}`}
              width={baseWidth}
              height={baseHeight}
            />

            <PricerPin
              store={selected}
              pulse={pulse}
              locations={locations}
              imageSize={{
                width: usedWidth,
                height: usedHeight,
              }}
            />
          </Map>
        </TransformComponent>

        <ResetSize selected={selected} />

        {!!buttons && <ButtonContainer>{buttons}</ButtonContainer>}
      </TransformWrapper>
    </Root>
  )
}
