import React, {useCallback, useEffect, useState} from 'react'

import dynamic from 'next/dynamic'
import classNames from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {useUpdateEffect} from 'react-use'
import SearchLineIcon from 'remixicon-react/SearchLineIcon'

import {styledTheme} from '@festi/common/themes'
import {setBodyScroll} from '@festi/common/utils/dom'
import {Expandable} from '@festi/common/components/common'
import {SearchType, sendSearchClickEvent} from '@festi/common/utils/tagmanager'

import useAlgoliaSearch from 'src/components/search/redesign/utilites/useAlgoliaSearch'

const SearchHistory = dynamic(() => import('./SearchHistory'), {ssr: false})
const QuickSearchHits = dynamic(() => import('./QuickSearchHits'), {ssr: false})

const Wrapper = styled.div`
  position: relative;
  max-width: 1072px;
  margin: 0 auto;
`

const SearchForm = styled.form`
  position: relative;

  .remixicon-icon {
    position: absolute;
    top: 14px;
    left: 16px;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
`

const SearchBox = styled.input`
  width: 100%;
  padding: 13px 16px 13px 56px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${palette('blue')};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid ${palette('grey')};
  background-color: ${palette('white')};
  transition: all 240ms ease-in-out;

  &::placeholder {
    color: ${palette('ui40Solid')};
  }

  &:focus-visible {
    outline: 0;
  }

  &.openDropdown {
    border: 1px solid ${palette('ui30Solid')};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const Hover = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1001;
  transition: all 240ms ease-in-out;
`

interface SearchBarProps {
  searchQuery: string
  openDropdown: boolean
  setTab: React.Dispatch<React.SetStateAction<boolean>>
  setHover: React.Dispatch<React.SetStateAction<boolean>>
  setTargeted: React.Dispatch<React.SetStateAction<boolean>>
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}

export default function Searchbar({
  searchQuery,
  openDropdown,
  setTab,
  setHover,
  setTargeted,
  setSearchQuery,
}: SearchBarProps) {
  const ref = React.useRef<HTMLInputElement>(null)
  const hoverRef = React.useRef<HTMLDivElement>(null)

  const [fakeSearchQuery, setFakeSearchQuery] = useState('')

  const {currQuery, handleSearchSubmit} = useAlgoliaSearch()

  const handleRemoveOpenState = useCallback(() => {
    setTab(false)
    setHover(false)
    setTargeted(false)
  }, [setTab, setHover, setTargeted])

  const handleSubmitQuery = useCallback(
    (query: string, type: SearchType) => {
      ref.current?.blur()
      handleSearchSubmit(query)
      handleRemoveOpenState()
      sendSearchClickEvent({search_term: query, search_type: type})
    },
    [handleSearchSubmit, handleRemoveOpenState],
  )

  useUpdateEffect(() => setFakeSearchQuery(currQuery), [currQuery])

  useEffect(() => {
    setBodyScroll(!openDropdown)
  }, [openDropdown])

  useEffect(() => {
    const parent = hoverRef.current

    const handleFocus = (event: FocusEvent) => {
      if (parent && parent.contains(event.target as Node)) {
        setTab(true)
      }
    }

    const handleBlur = (event: FocusEvent) => {
      if (parent && !parent.contains(event.relatedTarget as Node)) {
        setTab(false)
      }
    }

    parent.addEventListener('focusin', handleFocus)
    parent.addEventListener('focusout', handleBlur)

    return () => {
      parent.removeEventListener('focusin', handleFocus)
      parent.removeEventListener('focusout', handleBlur)
    }
  }, [setTab])

  return (
    <Wrapper>
      <SearchForm
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmitQuery(searchQuery || '', 'input_field')
        }}
      >
        <SearchLineIcon
          size={22}
          color={styledTheme.palette.blue}
          onClick={() => handleSearchSubmit(searchQuery || '')}
        />

        <SearchBox
          ref={ref}
          value={searchQuery || fakeSearchQuery}
          placeholder="Ég er að leita að ..."
          className={classNames({openDropdown})}
          onBlur={() => setTargeted(false)}
          onFocus={() => setTargeted(true)}
          onChange={(e) => {
            setFakeSearchQuery('')
            setSearchQuery(e.target.value)
          }}
        />
      </SearchForm>

      <Hover
        ref={hoverRef}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Expandable key="quicksearch" expanded={openDropdown}>
          {searchQuery ? (
            <QuickSearchHits
              query={searchQuery}
              handleClose={() => {
                setSearchQuery('')
                handleRemoveOpenState()
              }}
              handleSubmitQuery={handleSubmitQuery}
            />
          ) : (
            <SearchHistory handleSubmitQuery={handleSubmitQuery} />
          )}
        </Expandable>
      </Hover>
    </Wrapper>
  )
}
