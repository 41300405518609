import {useCallback} from 'react'

import Link from 'next/link'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import ShoppingBasket2LineIcon from 'remixicon-react/ShoppingBasket2LineIcon'

import {WishlistItem} from '@festi/common/api/rest'
import {ProductPrices} from '@festi/common/components/cart/ProductPrices'
import {getListingPrice} from '@festi/common/utils/price'
import {AugmentedUserPrice} from '@festi/common/utils/rest'
import {useWishlist} from '@festi/common/utils/wishlists'
import {fluidRange} from '@festi/common/utils/styles'
import {CloseButton} from '@festi/common/components/buttons'
import {MiniAddToCartButton} from '@festi/common/components/cart'
import {useCommonContext} from '@festi/common/contexts/common'

import ProductTags from '../utilities/ProductTags'
import WebpImage from 'src/components/common/WebpImage'

const VariantCardWrapper = styled.div`
  position: relative;
  min-width: 280px;
  width: 100%;
  height: 100%;
  background-color: ${palette('white')};
  border-radius: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: 12px;
    transition: all 0.2s;
    pointer-events: none;
    z-index: 1;
  }

  &:hover:before {
    border-color: ${palette('ui20Solid')};
  }
`

const VariantCardContent = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 20px 20px 20px;

  ${media.md`
    padding: 30px 24px 24px 24px;
  `}
`

const Close = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

const VariantInfo = styled.div`
  display: flex;
  ${fluidRange('gap', '12px', '16px')};
  width: 100%;
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fluidRange('width', '100px', '120px')}
  ${fluidRange('height', '100px', '120px')}
  padding-top: 12px;
`

const VariantDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media (max-width: 425px) {
    gap: 6px;
  }
`

const VariantCardInfoAnchor = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
  min-height: 54px;
  margin-top: 12px;
  gap: 5px;

  ${media.md`
    padding-right: 24px;
  `}
`

const VariantCardName = styled.div`
  font-size: 1rem;
  line-height: 22px;
  font-weight: 500;
  color: ${palette('blue')};
  text-align: left;
`

const VariantCardSku = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${palette('ui50Solid')};
  text-align: left;
`

const PriceWithButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 4px;

  @media (max-width: 425px) {
    flex-direction: column;
    flex-shrink: 0;
    gap: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
`

interface Props {
  item: WishlistItem
  userPrice?: AugmentedUserPrice
  userPriceLoading?: boolean
  onClick?: () => void
}

export default function NewWishlistItemCard({item, userPrice, onClick}: Props) {
  const {firstImage, sku, name, product} = item.variant
  const price = getListingPrice(item.variant)

  const {removeVariantFromWishlist} = useWishlist()
  const {handleCopyText} = useCommonContext()

  const onClickRemove = useCallback(() => {
    removeVariantFromWishlist(item.sku)
  }, [removeVariantFromWishlist, item.sku])

  return (
    <VariantCardWrapper>
      <ProductTags variant={item.variant} />

      <VariantCardContent>
        <Close>
          <CloseButton onClick={onClickRemove} />
        </Close>

        <VariantInfo>
          <ImageWrapper>
            <WebpImage
              webp={firstImage?.image?.webpS}
              jpg={firstImage?.image?.jpgS}
              alt={firstImage?.alt || name}
            />
          </ImageWrapper>

          <VariantDetails>
            <VariantCardInfoAnchor
              href={`/vorur/${product.slug}/${sku}/`}
              onClick={onClick}
            >
              <VariantCardName>{name}</VariantCardName>

              <VariantCardSku onClick={(e) => handleCopyText(e, sku)}>
                {sku}
              </VariantCardSku>
            </VariantCardInfoAnchor>

            <PriceWithButton>
              <PriceWrapper>
                <ProductPrices
                  size={fluidRange('font-size', '20px', '24px')}
                  oldPriceSize={fluidRange('font-size', '14px', '16px')}
                  price={userPrice?.price?.price || price?.price || 0}
                  lowestPrice={
                    userPrice?.lowestPrice || price?.lowestPrice || 0
                  }
                  discountedPrice={
                    userPrice?.userPrice || price?.discountedPrice
                  }
                />
              </PriceWrapper>

              <ButtonWrapper>
                <MiniAddToCartButton
                  icon={ShoppingBasket2LineIcon}
                  variant={item.variant}
                  newDesign
                />
              </ButtonWrapper>
            </PriceWithButton>
          </VariantDetails>
        </VariantInfo>
      </VariantCardContent>
    </VariantCardWrapper>
  )
}
