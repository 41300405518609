import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useEffect} from 'react'

import cn from 'classnames'
import keys from 'lodash/keys'
import sortBy from 'lodash/sortBy'
import times from 'lodash/times'
import uniq from 'lodash/uniq'
import Slider from 'react-slick'
import {useEffectOnce, useUpdateEffect, useWindowSize} from 'react-use'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {FieldType, VariantDetail} from '@festi/common/api/rest'
import {InfoBox, InfoSection} from '@festi/common/components/common'
import {useComparison} from '@festi/common/contexts'
import {gridTheme} from '@festi/common/themes'
import {useUserPrices, AugmentedUserPrice} from '@festi/common/utils/rest'
import {formatPrice} from '@festi/utils/numbers'
import {Dictionary} from '@festi/utils/types'
import {getListingPrice} from '@festi/common/utils/price'
import {getDiscount} from '@festi/common/utils/checkout'

interface Props {
  currSlide: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sliderSettings: {[key: string]: any}
  fieldTypeMap: Dictionary<FieldType>
}

interface TableSliderProps {
  sliderRef: React.MutableRefObject<Slider> | React.LegacyRef<Slider>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sliderSettings: {[key: string]: any}
  emptyCount: number
  children: React.ReactNode
}

interface AttributeLineProps {
  attribute: string
  height: number
  fieldTypeMap: Dictionary<FieldType>
}

const Wrapper = styled.div``

const TableWrapper = styled.div`
  margin-bottom: 24px;

  ${media.md`
    margin-bottom: 32px;
  `}
`

const TableHead = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${palette('blue')};
  text-align: left;
  padding: 8px 0;
  border-bottom: 1px solid ${palette('lightBlue')};
  width: 100%;

  ${media.md`
    padding: 12px 0;
  `}
`

const TableContent = styled.div`
  display: flex;
  width: 100%;
`

const TableLabelsContainer = styled.div`
  width: 20%;
`

interface TableLineProps {
  height: number
}

const TableLine = styled.div<TableLineProps>`
  position: relative;
  width: 100%;
  height: ${({height}) => `${height}px` || '0px'};
  box-sizing: border-box;
  border-bottom: 1px solid ${palette('ui20Solid')};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.875rem;
  color: ${palette('blue')};
  text-align: right;
  font-weight: 400;
  padding-right: 16px;
  line-height: 1.1;
  user-select: text;

  &:last-child {
    border-bottom: 0;
  }

  &.isLabel {
    text-align: left;
    font-weight: 500;
    justify-content: flex-start;
    border-right: 1px solid ${palette('ui5Solid')};
    padding-right: 0;
  }

  &.smallFont {
    font-size: 0.56rem;

    ${media.sm`
      font-size: 1rem;
    `}
  }

  ${InfoBox} {
    width: 300px;
  }

  ${media.md`
    font-size: 1rem;
  `}
`

const SliderContainer = styled.div`
  width: 80%;
  flex-grow: 1;

  .slick-list {
    overflow: hidden !important;
  }
`

const SlideWrapper = styled.div`
  width: 100%;

  &:focus {
    outline: none !important;
  }
`
interface UserPriceProps {
  variant: VariantDetail
  userPrice: AugmentedUserPrice
  hasDiscount: boolean
  tableLineDefaultHeight: number
}

function UserPriceTable({
  variant,
  userPrice,
  hasDiscount,
  tableLineDefaultHeight,
}: UserPriceProps) {
  const price = userPrice?.price?.price || getListingPrice(variant)?.price
  const lowestPrice =
    userPrice?.lowestPrice || getListingPrice(variant)?.lowestPrice
  const discount = userPrice?.discount || getDiscount(variant)

  return (
    <>
      <TableLine height={tableLineDefaultHeight}>
        {formatPrice(lowestPrice)}
      </TableLine>
      {hasDiscount && (
        <TableLine
          height={tableLineDefaultHeight}
          style={{textDecoration: 'line-through'}}
        >
          {formatPrice(price)}
        </TableLine>
      )}
      {hasDiscount && (
        <TableLine height={tableLineDefaultHeight}>
          {Math.round((discount || 0) * 100)}%
        </TableLine>
      )}
    </>
  )
}

function AttributeLine({attribute, height, fieldTypeMap}: AttributeLineProps) {
  const fieldType = fieldTypeMap[attribute]

  if (!fieldType.isProperty) {
    return null
  }

  return (
    <TableLine
      key={fieldType.key}
      height={height}
      className={cn({isLabel: true, smallFont: fieldType?.label.length > 20})}
    >
      {fieldType.label}
      {!!fieldType.info && <InfoSection info={fieldType.info} hoverOnDesktop />}
    </TableLine>
  )
}

function TableSlider({
  sliderRef,
  sliderSettings,
  emptyCount,
  children,
}: TableSliderProps) {
  return (
    <SliderContainer>
      <Slider ref={sliderRef} swipe={false} {...sliderSettings}>
        {children}
        {times(emptyCount, (index: number) => (
          <SlideWrapper key={index} />
        ))}
      </Slider>
    </SliderContainer>
  )
}

export default function ComparisonTable({
  currSlide,
  sliderSettings,
  fieldTypeMap,
}: Props): JSX.Element {
  const {comparedProducts, comparisonCap, hasDiscount} = useComparison()

  const {data: userPrices} = useUserPrices(comparedProducts?.map((p) => p.sku))

  const allAttributes = useMemo(
    () =>
      sortBy(
        uniq(comparedProducts?.flatMap((p) => keys(p.attributes))),
        (k) => fieldTypeMap[k]?.index,
      ),
    [comparedProducts, fieldTypeMap],
  )

  const attributesWithValue = useMemo(
    () =>
      allAttributes.filter((k) =>
        comparedProducts?.some((p) => {
          const val = p.attributes[k]
          return !(Array.isArray(val) && val.length === 0) && val !== ''
        }),
      ),
    [allAttributes, comparedProducts],
  )

  const emptyCount = comparisonCap - comparedProducts?.length
  const priceSliderRef = useRef<Slider>(null)
  const infoSliderRef = useRef<Slider>(null)
  const [heights, setHeights] = useState<number[]>(
    Array(attributesWithValue.length),
  )
  // Initialize an array of refs to handle table row heights
  const tableLineRefs = Array(4).fill(Array(attributesWithValue?.length))
  const {width} = useWindowSize()
  const tableLineDefaultHeight = width < gridTheme.breakpoints.md ? 48 : 50
  const tableLinePadding = width < gridTheme.breakpoints.md ? 0 : 16

  const handleHeigtChanges = useCallback(() => {
    const heights = []
    setHeights(Array(attributesWithValue.length))

    tableLineRefs?.forEach((refs) => {
      refs.forEach((ref: HTMLDivElement, index: number) => {
        // Set height of table row as the height of the biggest element in the row
        if (
          ref?.clientHeight > tableLineDefaultHeight &&
          ref?.clientHeight > heights[index]
        ) {
          heights[index] = ref.clientHeight + tableLinePadding
        } else {
          heights[index] = tableLineDefaultHeight
        }
      })
    })
    setHeights(heights)
  }, [
    tableLineRefs,
    tableLinePadding,
    tableLineDefaultHeight,
    attributesWithValue.length,
  ])

  useEffectOnce(() => {
    handleHeigtChanges()
  })

  useUpdateEffect(() => {
    handleHeigtChanges()
  }, [comparedProducts])

  useEffect(() => {
    priceSliderRef?.current?.slickGoTo(currSlide)
    infoSliderRef?.current?.slickGoTo(currSlide)
  }, [currSlide])

  return (
    <Wrapper>
      <TableWrapper>
        <TableHead>Verð upplýsingar</TableHead>

        <TableContent>
          <TableLabelsContainer>
            <TableLine height={tableLineDefaultHeight} className="isLabel">
              Verð
            </TableLine>
            {hasDiscount && (
              <TableLine height={tableLineDefaultHeight} className="isLabel">
                Verð áður
              </TableLine>
            )}
            {hasDiscount && (
              <TableLine height={tableLineDefaultHeight} className="isLabel">
                Afsláttur
              </TableLine>
            )}
          </TableLabelsContainer>

          <TableSlider
            sliderRef={priceSliderRef}
            sliderSettings={sliderSettings}
            emptyCount={emptyCount}
          >
            {comparedProducts.map((product) => (
              <SlideWrapper key={product?.id}>
                <UserPriceTable
                  variant={product}
                  hasDiscount={hasDiscount}
                  userPrice={userPrices?.[product.sku]}
                  tableLineDefaultHeight={tableLineDefaultHeight}
                />
              </SlideWrapper>
            ))}
          </TableSlider>
        </TableContent>
      </TableWrapper>

      <TableWrapper>
        <TableHead>Eiginleikar</TableHead>

        <TableContent>
          <TableLabelsContainer>
            {attributesWithValue.map((attr, i) => {
              const fieldType = fieldTypeMap[attr]
              return fieldType?.isProperty ? (
                <AttributeLine
                  fieldTypeMap={fieldTypeMap}
                  attribute={attr}
                  key={attr}
                  height={heights[i]}
                />
              ) : null
            })}
          </TableLabelsContainer>

          <TableSlider
            sliderRef={(ref) => (infoSliderRef.current = ref)}
            sliderSettings={sliderSettings}
            emptyCount={emptyCount}
          >
            {comparedProducts.map((variant, i) => (
              <SlideWrapper key={variant.id}>
                {attributesWithValue.map((attr, j) => {
                  const fieldType = fieldTypeMap[attr]
                  return fieldType?.isProperty ? (
                    <TableLine
                      key={attr}
                      ref={(ref) => (tableLineRefs[i][j] = ref)}
                      height={heights[j]}
                    >
                      {variant.attributes[attr]}
                    </TableLine>
                  ) : null
                })}
              </SlideWrapper>
            ))}
          </TableSlider>
        </TableContent>
      </TableWrapper>
    </Wrapper>
  )
}
