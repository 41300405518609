import React from 'react'

import AddLineIcon from 'remixicon-react/AddLineIcon'
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {palette, ifProp} from 'styled-tools'
import {styledTheme} from '@festi/common/themes'

interface Props {
  qty: number
  onUpdate: (q: number) => void
  isMax?: boolean
  keepSmall?: boolean
}

interface PreferencesProps {
  keepSmall?: boolean
}

const Box = styled.div`
  background-color: ${palette('white')};
  border: 1px solid ${palette('border')};
  border-radius: 8px;
  display: inline-flex;
`

const Btn = styled.button<
  PreferencesProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  border: 0;
  background-color: transparent;
  height: 34px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.15s;
  padding: 0;
  border-radius: 0;

  .remixicon-icon {
    transition: opacity 0.15s;
  }

  &:disabled {
    cursor: not-allowed;

    .remixicon-icon {
      opacity: 0.2;
    }
  }

  &:hover:not(:disabled) {
    background-color: ${styledTheme.palette.ui5Solid};
  }

  ${ifProp(
    {keepSmall: true},
    css``,
    css`
      ${media.md`
      height: 46px;
      width: 46px;
    `}
    `,
  )}
`

const QtyNum = styled.div<PreferencesProps>`
  height: 34px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${palette('border')};
  border-right: 1px solid ${palette('border')};
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
  line-height: 1;

  ${ifProp(
    {keepSmall: true},
    css``,
    css`
      ${media.md`
      height: 46px;
      width: 46px;
    `}
    `,
  )}
`

export default function NewQtyPicker({
  qty,
  onUpdate,
  isMax,
  keepSmall,
}: Props): JSX.Element {
  return (
    <Box>
      <Btn
        onClick={() => onUpdate(qty - 1)}
        disabled={qty <= 1}
        keepSmall={keepSmall}
        aria-label="Minus"
      >
        <SubtractLineIcon size={16} color={styledTheme.palette.blue} />
      </Btn>
      <QtyNum keepSmall={keepSmall}>{qty}</QtyNum>
      <Btn
        onClick={() => onUpdate(qty + 1)}
        disabled={isMax}
        keepSmall={keepSmall}
        aria-label="Plus"
      >
        <AddLineIcon size={16} color={styledTheme.palette.blue} />
      </Btn>
    </Box>
  )
}
