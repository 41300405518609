import Link from 'next/link'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import HeartLineIcon from 'remixicon-react/HeartLineIcon'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'

import {styledTheme} from '@festi/common/themes'
import {useUserPrices} from '@festi/common/utils/rest'
import {useWishlist} from '@festi/common/utils/wishlists'
import {DotsLoader, EmptyResults} from '@festi/common/components/common'
import {SideDrawer} from '@festi/common/components/layout'
import {fluidRange} from '@festi/common/utils/styles'
import {CloseButton} from '@festi/common/components/buttons'

import NewWishlistItemCard from './NewWishlistItemCard'
import {DrawerHeader} from 'src/components/search/redesign/components/SideDrawer'

interface Props {
  isOpen?: boolean
  onRequestClose?: () => void
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const WishlistWrapper = styled.div`
  width: 100%;
  height: 100dvh;
  padding-top: 120px;
  overflow-y: auto;
`

const WishlistHead = styled.div`
  display: flex;
  font-weight: 500;
  color: ${palette('blue')};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-left', '16px', '40px')};
`

const WishlistButtons = styled.div`
  display: flex;
  gap: 4px;
`

const RemoveAllButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: auto;
  padding: 7px 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: ${palette('white')};
  color: ${palette('blue')};
  font-size: 16px;
  font-weight: 500;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background-color: ${styledTheme.palette.ui5Solid};
    border: 1px solid ${styledTheme.palette.ui10Solid};
  }

  @media (max-width: 425px) {
    border-color: #d8dde6;
    background-color: transparent;
  }
`

const TextAnchor = styled(Link)`
  display: flex;
  align-items: center;
  color: ${palette('blue')};
  font-weight: 500;
  text-decoration: none;

  svg {
    margin-left: 8px;
  }
`

const WishlistProducts = styled.div`
  display: flex;
  flex-direction: column;
  ${fluidRange('gap', '8px', '12px')};
  ${fluidRange('padding-top', '24px', '40px')};
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-left', '16px', '40px')};
  ${fluidRange('padding-bottom', '24px', '40px')};
  overflow-y: auto;
`

export default function NewWishlistDrawer({
  isOpen,
  onRequestClose,
}: Props): JSX.Element {
  const {wishlistItems, isValidating, removeAllFromWishlist} = useWishlist()

  const {data: userPrices, loading: userPriceLoading} = useUserPrices(
    wishlistItems?.map((w) => w.sku),
  )

  const len = wishlistItems?.length
  const isLoading = !wishlistItems && isValidating

  return (
    <SideDrawer
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      noFocus={isLoading || !len}
      newDesign
    >
      {isLoading ? (
        <DotsLoader />
      ) : (
        <ContentWrapper>
          <WishlistWrapper>
            <DrawerHeader>
              <WishlistHead>
                <span>Óskalistinn þinn</span>
              </WishlistHead>

              <CloseButton onClick={onRequestClose} />
            </DrawerHeader>

            {wishlistItems?.length === 0 ? (
              <EmptyResults icon={HeartLineIcon}>
                <span data-cy="wishlist-empty">Listinn er tómur</span>
              </EmptyResults>
            ) : (
              <>
                <ButtonWrapper>
                  <WishlistButtons>
                    <RemoveAllButton
                      onClick={removeAllFromWishlist}
                      data-cy="remove-all-from-wishlist"
                    >
                      Eyða öllu
                    </RemoveAllButton>
                  </WishlistButtons>

                  <TextAnchor href="/oskalisti" onClick={onRequestClose}>
                    <span>Sjá allar vörur</span>

                    <ArrowRightLineIcon
                      size={20}
                      color={styledTheme.palette.lightBlue}
                    />
                  </TextAnchor>
                </ButtonWrapper>

                <WishlistProducts>
                  {wishlistItems?.map((item) => (
                    <NewWishlistItemCard
                      key={item.sku}
                      item={item}
                      userPrice={userPrices?.[item.sku]}
                      userPriceLoading={userPriceLoading}
                      onClick={onRequestClose}
                    />
                  ))}
                </WishlistProducts>
              </>
            )}
          </WishlistWrapper>
        </ContentWrapper>
      )}
    </SideDrawer>
  )
}
