import styled from 'styled-components'

const Picture = styled.picture`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`

interface Props {
  webp: string
  jpg: string
  alt?: string
  onError?: () => void
}

export default function WebpImage({webp, jpg, alt, onError}: Props) {
  return (
    <Picture>
      <source srcSet={webp} type="image/webp" />
      <source srcSet={jpg} type="image/jpeg" />
      <Image src={jpg} alt={alt || `Missing alt ${webp}`} onError={onError} />
    </Picture>
  )
}
