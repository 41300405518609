import Image from 'next/image'
import styled from 'styled-components'

import {H2, P} from '@festi/common/components/typography'
import {useWindowSize} from 'react-use'

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
  width: 100%;
  height: 800px;
  margin: 30px 0;
`

export default function PricerEmptyMap() {
  const {height} = useWindowSize()

  const maxHeight = 1000

  const calculatedHeight = (height > maxHeight ? maxHeight : height) - 200

  return (
    <NotFound style={{height: calculatedHeight}}>
      <Image
        src="/icons/map-not-found.svg"
        alt="Map not found"
        width={82}
        height={99}
        style={{width: '82px', height: '99px'}}
      />

      <div>
        <H2 headline center>
          Fannst ekki
        </H2>

        <P center>
          Verðmerking fyrir þessa vöru fannst ekki í þessari verslun.
        </P>
      </div>
    </NotFound>
  )
}
