import * as SwitchPrimitive from '@radix-ui/react-switch'
import * as LabelPrimitive from '@radix-ui/react-label'
import styled from 'styled-components'
import {styledTheme} from '@festi/common/themes'
import classNames from 'classnames'

interface SwitchProps extends SwitchPrimitive.SwitchProps {
  id: string
  label?: string
  strong?: boolean
}

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled(LabelPrimitive.Root)`
  font-size: 16px;
  font-weight: 400;
  padding-left: 16px;
  color: ${styledTheme.palette.blue};
  cursor: pointer;

  &.strong {
    font-weight: 500;
  }
`

const SwitchRoot = styled(SwitchPrimitive.Root)`
  all: unset;
  width: 48px;
  height: 24px;
  border-radius: 12px;
  background-color: ${styledTheme.palette.ui10Solid};
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &[data-state='checked'] {
    background-color: ${styledTheme.palette.lightBlue};
  }
  &[data-disabled] {
    cursor: not-allowed;
  }
  &[data-disabled] + ${Label} {
    cursor: not-allowed;
  }
`

const SwitchThumb = styled(SwitchPrimitive.Thumb)`
  display: block;
  width: 20px;
  height: 20px;
  background-color: ${styledTheme.palette.white};
  border: 1px solid ${styledTheme.palette.grey};
  border-radius: 50%;
  transition: transform 100ms ease-out;
  transform: translateX(2px);
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(25px);
    border-color: ${styledTheme.palette.ui30Solid};
  }
`

export default function Switch({
  id,
  label,
  strong = false,
  ...props
}: SwitchProps) {
  return (
    <SwitchWrapper>
      <SwitchRoot id={id} {...props}>
        <SwitchThumb />
      </SwitchRoot>
      {label && (
        <Label htmlFor={id} className={classNames({strong})}>
          {label}
        </Label>
      )}
    </SwitchWrapper>
  )
}
