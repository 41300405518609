import {useCallback} from 'react'

import Link from 'next/link'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {WishlistItem} from '@festi/common/api/rest'
import {AddToCartButton} from '@festi/common/components/cart'
import {ProductPrices} from '@festi/common/components/cart/ProductPrices'
import {styledTheme} from '@festi/common/themes'
import {getListingPrice} from '@festi/common/utils/price'
import {AugmentedUserPrice} from '@festi/common/utils/rest'
import {useWishlist} from '@festi/common/utils/wishlists'

const VariantCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${palette('ui20Solid')};
  position: relative;
  display: flex;
  flex-direction: column;
`

const VariantCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;

  ${media.md`
    padding: 8px 16px;
  `}
`

const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${palette('ui10Solid')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  cursor: pointer;
`

const VariantCardImg = styled.div`
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 64%;
  margin-top: 8px;
  margin-bottom: 12px;
  max-height: 90px;
  transition: max-height 0.15s;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

const VariantCardInfoAnchor = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  cursor: pointer;
  margin-top: 8px;

  ${media.md`
    align-items: flex-start;
    padding-right: 32px;
    margin-bottom: 8px;
  `}
`

const VariantCardName = styled.div`
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 500;
  color: ${palette('blue')};
  margin-bottom: 5px;
  text-align: right;

  ${media.md`
    text-align: left;
  `}
`

const VariantCardSku = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${palette('ui50Solid')};
  text-align: right;

  ${media.md`
    text-align: left;
  `}
`

const VariantInfo = styled.div`
  display: flex;
  width: 100%;
`

const VariantDetails = styled.div``

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 8px;
`

interface Props {
  item: WishlistItem
  userPrice?: AugmentedUserPrice
  userPriceLoading?: boolean
  onClick?: () => void
}

export default function WishlistItemCard({item, userPrice, onClick}: Props) {
  const {firstImage, sku, name, product} = item.variant
  const price = getListingPrice(item.variant)
  const imgSrc = firstImage?.image?.jpgS

  const {removeVariantFromWishlist} = useWishlist()

  const onClickRemove = useCallback(() => {
    removeVariantFromWishlist(item.sku)
  }, [removeVariantFromWishlist, item.sku])

  return (
    <VariantCardWrapper>
      <VariantCardContent>
        <RemoveButton onClick={onClickRemove} aria-label="Remove">
          <CloseLineIcon size={18} color={styledTheme.palette.blue} />
        </RemoveButton>

        <VariantInfo>
          <VariantCardImg style={{backgroundImage: `url('${imgSrc}')`}} />

          <VariantDetails>
            <VariantCardInfoAnchor
              href={`/vorur/${product.slug}/${sku}/`}
              onClick={onClick}
            >
              <VariantCardName>{name}</VariantCardName>
              <VariantCardSku>{sku}</VariantCardSku>
            </VariantCardInfoAnchor>

            <PriceWrapper>
              <ProductPrices
                type="small"
                price={userPrice?.price?.price || price?.price || 0}
                lowestPrice={userPrice?.lowestPrice || price?.lowestPrice || 0}
                discountedPrice={
                  userPrice?.userPrice || price?.discountedPrice || 0
                }
              />
            </PriceWrapper>
          </VariantDetails>
        </VariantInfo>
      </VariantCardContent>

      <AddToCartButton variant={item.variant} buttonText="Bæta í körfu" />
    </VariantCardWrapper>
  )
}
