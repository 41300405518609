import React, {useState} from 'react'

import classNames from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'

import HeaderLogo from './HeaderLogo'
import HeaderMenu from './HeaderMenu'
import HeaderSearchbar from './searchbar'
import HeaderUtilites from './HeaderUtilities'
import DutyfreeLine from './DutyfreeLine'
import DutyfreeBanner from './DutyfreeBanner'
import useHeaderHeight from './utilites/useHeaderHeight'
import {
  headerDesktop,
  headerMobile,
  headerMobileHideScroll,
} from './utilites/sizes'
import useAlgoliaSearch from 'src/components/search/redesign/utilites/useAlgoliaSearch'

const HeaderPadding = styled.div`
  display: grid;
  grid-template-rows: ${headerMobile}px;
  transition: 150ms ease-in;

  &.hideSearchbar {
    grid-template-rows: ${headerMobileHideScroll}px;
  }

  ${media.md`
    grid-template-rows: ${headerDesktop}px;
  `}
`

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-rows: ${headerMobile}px;
  width: 100%;
  padding: 0 16px;
  background-color: ${palette('ui4Solid')};
  border-bottom: 1px solid ${palette('border')};
  transition: 150ms ease-in-out;
  z-index: 1000;

  &.hideSearchbar {
    grid-template-rows: ${headerMobileHideScroll}px;
  }

  ${media.md`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    height: ${headerDesktop}px;
  `}
`

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'logo utilities menu'
    'line line line'
    'search search search';
  grid-template-columns: auto 1fr auto;
  gap: 2px 4px;
  width: 100%;
  padding: 6px 0 8px 0;
  overflow: hidden;
  transition: 150ms ease-in-out;

  &.openDropdown {
    overflow: visible;
  }

  ${media.md`
    grid-template-areas:
    'logo menu search utilities'
    'line line line line';
    grid-template-columns: auto auto 1fr auto;
    gap: 0 10px;
    padding: 0;
    overflow: visible;
  `}
`

const Line = styled.div`
  grid-area: line;
`

const Logo = styled.div`
  grid-area: logo;
  height: 50px;
`

const Menu = styled.div`
  grid-area: menu;
`

const Search = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-area: search;
  width: 100%;
  transition: 150ms ease-in-out;

  &.hideSearchbar {
    grid-template-rows: 0fr;
  }
`

const OverflowWrapper = styled.div`
  overflow: hidden;

  &.openDropdown {
    overflow: visible;
  }

  ${media.md`
    overflow: visible;
  `}
`

const Utilities = styled.div`
  grid-area: utilities;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
`

interface Props {
  onClickCart: () => void
  onClickLogin: () => void
  onClickWishlist: () => void
}

export default function Header({
  onClickCart,
  onClickLogin,
  onClickWishlist,
}: Props) {
  const {hideSearchbar} = useHeaderHeight()
  const {searchHistory} = useAlgoliaSearch()

  const [searchQuery, setSearchQuery] = useState('')

  const [tab, setTab] = useState(false)
  const [hover, setHover] = useState(false)
  const [targeted, setTargeted] = useState(false)

  const openDropdown =
    (hover || targeted || tab) && (!!searchQuery || !!searchHistory?.length)

  return (
    <>
      <HeaderPadding className={classNames({hideSearchbar})} />

      <Root className={classNames({hideSearchbar})}>
        <Grid className={classNames({openDropdown})}>
          <Line>
            <DutyfreeLine hideSearchbar={hideSearchbar} />
          </Line>

          <Logo>
            <HeaderLogo />
          </Logo>

          <Menu>
            <HeaderMenu />
          </Menu>

          <Search className={classNames({hideSearchbar})}>
            <OverflowWrapper className={classNames({openDropdown})}>
              <HeaderSearchbar
                searchQuery={searchQuery}
                openDropdown={openDropdown}
                setTab={setTab}
                setHover={setHover}
                setTargeted={setTargeted}
                setSearchQuery={setSearchQuery}
              />
            </OverflowWrapper>
          </Search>

          <Utilities>
            <HeaderUtilites
              onClickCart={onClickCart}
              onClickLogin={onClickLogin}
              onClickWishlist={onClickWishlist}
            />
          </Utilities>
        </Grid>
      </Root>

      <DutyfreeBanner />
    </>
  )
}
