import Searchbar from './Searchbar'

interface HeaderSearchbarProps {
  searchQuery: string
  openDropdown: boolean
  setHover: React.Dispatch<React.SetStateAction<boolean>>
  setTab: React.Dispatch<React.SetStateAction<boolean>>
  setTargeted: React.Dispatch<React.SetStateAction<boolean>>
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}

export default function HeaderSearchbar({
  searchQuery,
  openDropdown,
  setTab,
  setHover,
  setTargeted,
  setSearchQuery,
}: HeaderSearchbarProps) {
  return (
    <Searchbar
      searchQuery={searchQuery}
      openDropdown={openDropdown}
      setTab={setTab}
      setHover={setHover}
      setTargeted={setTargeted}
      setSearchQuery={setSearchQuery}
    />
  )
}
