import React from 'react'

import {uniq} from 'lodash'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, switchProp} from 'styled-tools'

import {isNewProduct, ProductTagVariant} from '@festi/common/utils/products'
import {AugmentedUserPrice} from '@festi/common/utils/rest'
import {VariantDetail, VariantList} from '@festi/common/api/rest'
import {getDiscount} from '@festi/common/utils/checkout'

export const productTagMap = {
  discount: 'Afsláttur',
  new: 'Nýtt',
  outlet: 'Tækifæri',
  navEmm: 'ELKO mælir með',
}

interface VariantProps {
  variant: ProductTagVariant
}

const Tag = styled.div<VariantProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  overflow: hidden;
  background: ${switchProp('variant', {
    discount: palette('pink'),
    new: palette('blue'),
    outlet: palette('black'),
  })};

  ${media.md`
    height: 64px;
    width: 64px;
  `}
`

const Label = styled.span`
  font-family: 'ElkjopHeadline', sans-serif;
  font-size: 1.3rem;
  color: ${palette('blue')};
  line-height: 1;

  ${media.md`
    font-size: 1.5rem;
  `}
`

const LabelBubble = styled.div<VariantProps>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${switchProp('variant', {
    new: palette('blue'),
    outlet: palette('black'),
  })};
  display: flex;
  align-items: center;
  justify-content: center;

  ${Label} {
    font-size: 1.125rem;
    color: ${switchProp('variant', {
      new: palette('white'),
      outlet: palette('warning'),
    })};
  }
`

export const formatTags = (
  variant: VariantDetail | VariantList,
): ProductTagVariant[] => {
  const {recommended, outlet, product} = variant
  const {online} = product
  const discount = getDiscount(variant)

  const tags = []
  if (discount) {
    tags.push(ProductTagVariant.Discount)
  }
  if (isNewProduct(online)) {
    tags.push(ProductTagVariant.New)
  }
  if (recommended) {
    tags.push(ProductTagVariant.isRecommended)
  }
  if (outlet) {
    tags.push(ProductTagVariant.Outlet)
  }
  return tags
}

interface ProductTagProps {
  variant: ProductTagVariant
  discount?: number
}

export default function ProductTag({
  variant,
  discount,
}: ProductTagProps): JSX.Element {
  if (variant === ProductTagVariant.isRecommended) {
    return null
  }

  return (
    <Tag variant={variant}>
      {variant === ProductTagVariant.Discount && (
        <Label>-{Math.round(discount * 100)}%</Label>
      )}
      {[ProductTagVariant.New, ProductTagVariant.Outlet].includes(variant) && (
        <LabelBubble variant={variant}>
          <Label>{productTagMap[variant]}</Label>
        </LabelBubble>
      )}
    </Tag>
  )
}

interface Props {
  variant: VariantDetail | VariantList
  userPrice?: AugmentedUserPrice
}

export function ProductTags({variant, userPrice}: Props) {
  const tags = formatTags(variant)
  const discount = getDiscount(variant)

  const tagsWithDiscount = userPrice?.discount
    ? uniq([...tags, ProductTagVariant.Discount])
    : tags

  return (
    <>
      {tagsWithDiscount?.map((tag: ProductTagVariant) => (
        <ProductTag
          key={tag}
          variant={tag}
          discount={userPrice?.discount || discount}
        />
      ))}
    </>
  )
}
