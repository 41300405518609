import {useCallback, useEffect, useState} from 'react'

import {useMedia, useThrottle, useWindowScroll} from 'react-use'

import {gridTheme} from '@festi/common/themes'

import {headerDesktop, headerMobile, headerMobileHideScroll} from './sizes'

const headerOffset = headerMobile - headerMobileHideScroll

export default function useHeaderHeight() {
  const [show, setShow] = useState(true)
  const [height, setHeight] = useState<number>(null)
  const [offset, setOffset] = useState(0)

  const isDesktop = useMedia(`(min-width: ${gridTheme.breakpoints.md}px)`, true)
  const {y: windowY} = useWindowScroll()

  const throttledIsDesktop = useThrottle(isDesktop, 400)
  // const showSearchbar = useThrottle(throttledIsDesktop || show, 100)
  const headerHeight = useThrottle(
    throttledIsDesktop ? headerDesktop : height,
    100,
  )

  const handleResize = useCallback((show: boolean) => {
    setShow(show)
    setHeight(show ? headerMobile : headerMobileHideScroll)
  }, [])

  const handleScroll = useCallback(() => {
    const currentY = window.scrollY
    const currentYwithOffset = currentY - offset

    if (currentY === 0) {
      handleResize(true)
      setOffset(0)
    } else if (currentYwithOffset >= windowY) {
      handleResize(false)
      setOffset(-headerOffset + 10)
    } else {
      handleResize(true)
      setOffset(headerOffset + 10)
    }
  }, [offset, windowY, handleResize])

  const scrollEventListener = useCallback(() => {
    if (!throttledIsDesktop) {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [throttledIsDesktop, handleScroll])

  useEffect(scrollEventListener, [scrollEventListener])

  return {
    headerHeight,
    hideSearchbar: !show,
    // hideSearchbar: !showSearchbar, // TODO: Do we need the throttle? The transition is smoother without it!
    scrollEventListener,
  }
}
