import React, {useState, useCallback, useEffect} from 'react'

import {OperationVariables} from '@apollo/client'
import Head from 'next/head'
import {useRouter} from 'next/router'
import {
  renderMetaTags,
  SeoOrFaviconTag,
  useQuerySubscription,
} from 'react-datocms'
import styled, {keyframes} from 'styled-components'
import {palette} from 'styled-tools'
import {useMedia} from 'react-use'

import {Webpage} from '@festi/common/api/datocms/types/WebPage'
import {QueryListenerOptions} from '@festi/common/api/datocms/utils'
import {AllPageNavigations} from '@festi/common/api/datocms/types/AllPageNavigations'
import {allPageNavigationsQuery} from '@festi/common/api/datocms/queries'
import {Category} from '@festi/common/api/rest'
import {
  AuthDrawer,
  UserDrawer,
  PageNavDrawer,
  BusinessDrawer,
} from '@festi/common/components/account'
import {MiniCartDrawer} from '@festi/common/components/cart'
import {NotificationBanner} from '@festi/common/components/common'
import {Footer, Header} from '@festi/common/components/layout'
import {NewCategoriesDrawer} from '@festi/common/components/megamenu'
import {WishlistDrawer} from '@festi/common/components/wishlist'
import {useAuth, useComparison} from '@festi/common/contexts'
import {onDutyFree} from '@festi/common/constants/channels'
import {CommonContext} from '@festi/common/contexts/common'
import {BreadCrumb} from '@festi/common/utils/categories'
import useDatoCMS from '@festi/common/utils/useDatoCMS'
import settings from '@festi/common/constants/settings'
import {CheckoutFooter} from '@festi/common/components/checkout'

import {BreadCrumbsBar, RedesignHeader} from 'src/components/layout'
import NewMiniCartDrawer from '../cards/redesign/cart/NewMiniCartDrawer'
import NewWishlistDrawer from '../cards/redesign/wishlist/NewWishlistDrawer'
import {ComparisonBanner, ComparisonSearchModal} from '../comparison'

export enum DrawerState {
  categoriesOpen,
  authOpen,
  cartOpen,
  noneOpen,
  wishlistOpen,
  userOpen,
  businessOpen,
  infoOpen,
}

export interface WebPageCommonProps {
  categories: Category[]
  cmsSubscription: QueryListenerOptions<Webpage, OperationVariables>
}

interface WrapperProps {
  backgroundColor?: string
}

const PageWrapper = styled.div<WrapperProps>`
  background-color: ${(p) =>
    p.backgroundColor ? palette(p.backgroundColor) : 'transparent'};
`

const PageContent = styled.div`
  min-height: 100vh;
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

interface FloatingNotificationProps {
  x: number
  y: number
}

const FloatingNotification = styled.div<FloatingNotificationProps>`
  position: fixed;
  top: ${({y}) => y + 10}px; // Slightly offset from cursor
  left: ${({x}) => x + 10}px;
  z-index: 1000;
  font-size: 12px;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  background-color: ${palette('ui30Solid')};
  animation: ${fadeOut} 1500ms ease-in-out;
  pointer-events: none;
`

export interface WebPageProps {
  headTitle?: string
  children: React.ReactNode
  backgroundColor?: string
  common: WebPageCommonProps
  breadCrumbs?: BreadCrumb[]
  overflow?: string
  isCheckout?: boolean
  seoIndexing?: boolean
  myaccount?: boolean
  datoMeta?: SeoOrFaviconTag[]
  attrNameMap?: Record<string, string>
  hasBackButton?: boolean
}

export default function WebPage({
  headTitle,
  children,
  backgroundColor,
  common: {categories, cmsSubscription},
  breadCrumbs,
  overflow = 'hidden',
  isCheckout = false,
  seoIndexing = true,
  myaccount = false,
  datoMeta,
  hasBackButton = false,
}: WebPageProps): JSX.Element {
  const router = useRouter()
  const {user, missingAttributes, promptAuth, setPromptAuth} = useAuth()
  const {pathname} = useRouter()
  const {isComparing, comparisonSearchOpen, toggleComparisonSearch} =
    useComparison()
  const [drawer, setDrawer] = useState<DrawerState>(DrawerState.noneOpen)
  const [activeNavItem, setActiveNavItem] = useState<string | null>(null)
  const [pageTopMargin, setPageTopMargin] = useState<number>(
    onDutyFree ? 33 : 0,
  )
  const [copied, setCopied] = useState(false)
  const [position, setPosition] = useState({x: 0, y: 0})

  const {data: cmsData} = useQuerySubscription<Webpage>(cmsSubscription)
  const {data: pageNavigation} = useDatoCMS<AllPageNavigations>(
    allPageNavigationsQuery,
    {
      variables: {
        path: '',
      },
    },
  )

  const isDesktop = useMedia('(min-width: 768px)', true)

  const notificationData = cmsData?.notification
  const showNotification =
    (notificationData?.presentation === 'checkout' && isCheckout) ||
    notificationData?.presentation === 'global' ||
    (notificationData?.presentation === 'myaccount' && myaccount)

  const categoriesOpen = drawer === DrawerState.categoriesOpen
  const authOpen = drawer === DrawerState.authOpen
  const cartOpen = drawer === DrawerState.cartOpen
  const wishlistOpen = drawer === DrawerState.wishlistOpen
  const userOpen = drawer === DrawerState.userOpen
  const businessOpen = drawer === DrawerState.businessOpen
  const infoOpen = drawer === DrawerState.infoOpen

  const onDutyFreeFrontPage = onDutyFree && pathname === '/'

  const onClickCart = useCallback(() => {
    if (isCheckout) {
      router.push('/karfa')
    } else {
      setDrawer(cartOpen ? DrawerState.noneOpen : DrawerState.cartOpen)
    }
  }, [isCheckout, router, cartOpen])

  const onClickWishlist = useCallback(() => {
    setDrawer(wishlistOpen ? DrawerState.noneOpen : DrawerState.wishlistOpen)
  }, [wishlistOpen])

  const onClickInformation = useCallback(() => {
    setDrawer(infoOpen ? DrawerState.noneOpen : DrawerState.infoOpen)
  }, [infoOpen])

  const onSearch = useCallback(
    (searchValue: string) => {
      router.push({pathname: '/leit', query: {q: searchValue}})
    },
    [router],
  )

  const closeDrawers = useCallback(() => {
    setDrawer(DrawerState.noneOpen)
  }, [])

  const handleOpenInformationDrawer = useCallback((type: string) => {
    setDrawer(DrawerState.infoOpen)
    setActiveNavItem(type)
  }, [])

  const handleCopyText = useCallback(
    async (event: React.MouseEvent<HTMLElement>, text: string) => {
      if (isDesktop) {
        event.preventDefault()

        const range = document.createRange()
        const selection = window.getSelection()

        range.selectNodeContents(event.target as Node)
        selection.removeAllRanges()
        selection.addRange(range)

        try {
          await navigator.clipboard.writeText(text)
          setCopied(true)

          const {clientX, clientY} = event
          setPosition({x: clientX, y: clientY})

          setTimeout(() => setCopied(false), 1500)
        } catch (err) {
          setCopied(false)
          console.error('Failed to copy: ', err)
        }
      }
    },
    [isDesktop],
  )

  useEffect(() => {
    if (promptAuth) {
      setDrawer(DrawerState.authOpen)
    } else {
      setDrawer(DrawerState.noneOpen)
    }
  }, [promptAuth, setDrawer])

  const content = (
    <>
      <Head>
        {datoMeta ? (
          renderMetaTags(datoMeta)
        ) : (
          <>
            <title>{headTitle || 'ELKO'}</title>
            <meta name="og:title" content={headTitle} />
          </>
        )}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="robots"
          content={seoIndexing ? 'INDEX,FOLLOW' : 'NOINDEX,NOFOLLOW'}
        />
      </Head>

      <main>
        <PageWrapper
          backgroundColor={backgroundColor}
          style={{
            overflow,
            paddingBottom: isComparing ? '120px' : '0',
          }}
        >
          <NewCategoriesDrawer
            isOpen={categoriesOpen && categories.length > 0} // only show if categories are available
            onRequestClose={closeDrawers}
            onDutyFreeFrontPage={onDutyFreeFrontPage}
          />
          <AuthDrawer
            isOpen={(authOpen && !user) || (user && missingAttributes)}
            onRequestClose={() => setPromptAuth(false)}
          />
          {settings.redesign ? (
            <NewWishlistDrawer
              isOpen={wishlistOpen}
              onRequestClose={() => setDrawer(DrawerState.noneOpen)}
            />
          ) : (
            <WishlistDrawer
              isOpen={wishlistOpen}
              onRequestClose={() => setDrawer(DrawerState.noneOpen)}
            />
          )}
          {settings.redesign ? (
            <NewMiniCartDrawer
              isOpen={cartOpen}
              onRequestClose={() => setDrawer(DrawerState.noneOpen)}
              onDutyFreeFrontPage={onDutyFreeFrontPage}
            />
          ) : (
            <MiniCartDrawer
              isOpen={cartOpen}
              onRequestClose={() => setDrawer(DrawerState.noneOpen)}
              onDutyFreeFrontPage={onDutyFreeFrontPage}
            />
          )}
          <UserDrawer
            isOpen={userOpen}
            onRequestClose={() => setDrawer(DrawerState.noneOpen)}
            onShowAllBusiness={() => setDrawer(DrawerState.businessOpen)}
            onWeb
          />
          <BusinessDrawer
            isOpen={businessOpen}
            onUserLogin={() => setDrawer(DrawerState.businessOpen)}
            onRequestClose={() => setDrawer(DrawerState.noneOpen)}
            onReturnToUser={() => setDrawer(DrawerState.userOpen)}
          />
          {!settings.redesign && (
            <PageNavDrawer
              isOpen={infoOpen}
              onRequestClose={() => setDrawer(DrawerState.noneOpen)}
            />
          )}

          {settings.redesign ? (
            <RedesignHeader
              onClickCart={onClickCart}
              onClickLogin={() => setPromptAuth(!promptAuth)}
              onClickWishlist={onClickWishlist}
            />
          ) : (
            <Header
              hideMobileSearch={drawer !== DrawerState.noneOpen}
              categoriesOpen={categoriesOpen}
              authOpen={authOpen}
              cartOpen={cartOpen}
              infoOpen={infoOpen}
              wishlistOpen={wishlistOpen}
              onClickCategories={() =>
                setDrawer(
                  categoriesOpen
                    ? DrawerState.noneOpen
                    : DrawerState.categoriesOpen,
                )
              }
              onClickLogin={() => setPromptAuth(!promptAuth)}
              onClickCart={onClickCart}
              onClickWishlist={onClickWishlist}
              onClickViewUser={() =>
                setDrawer(
                  userOpen ? DrawerState.noneOpen : DrawerState.userOpen,
                )
              }
              onClickInformation={onClickInformation}
              onSearch={onSearch}
              onWeb={true}
              path={router.asPath}
              closeDrawers={closeDrawers}
              onDutyFree={onDutyFree}
              pageTopMargin={pageTopMargin}
              setPageTopMargin={setPageTopMargin}
            />
          )}

          {showNotification && !onDutyFree && (
            <NotificationBanner
              icon={notificationData?.icon}
              color={notificationData?.color}
              title={notificationData?.headline}
              message={notificationData?.description}
            />
          )}

          {breadCrumbs?.length > 1 && (
            <BreadCrumbsBar
              breadCrumbs={breadCrumbs}
              hasBackButton={hasBackButton}
            />
          )}

          <PageContent style={{marginTop: pageTopMargin}}>
            {children}
          </PageContent>

          <ComparisonSearchModal
            isOpen={comparisonSearchOpen}
            onClose={toggleComparisonSearch}
          />

          <ComparisonBanner visible={isComparing} />

          {isCheckout ? (
            <CheckoutFooter />
          ) : (
            <Footer
              {...cmsData?.footer}
              activeNavItem={
                drawer !== DrawerState.noneOpen ? activeNavItem : null
              }
              onClickServiceLinks={handleOpenInformationDrawer}
            />
          )}
        </PageWrapper>
      </main>
    </>
  )

  return (
    <CommonContext.Provider
      value={{
        categories,
        openingHours: cmsData?.openingHoursGeneral,
        specialHours: cmsData?.allSpecialHoursPeriods,
        openingHoursDutyfree: cmsData?.openingHoursDutyfree,
        pageNavigation,
        activeNavItem,
        handleCopyText,
        setActiveNavItem,
      }}
    >
      {content}

      {copied && (
        <FloatingNotification x={position.x} y={position.y}>
          Afritað
        </FloatingNotification>
      )}
    </CommonContext.Provider>
  )
}
