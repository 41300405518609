import {useCallback, useState} from 'react'

import Link from 'next/link'
import TagManager from 'react-gtm-module'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'

import {CheckoutLine, CheckoutLineInsurance} from '@festi/common/api/rest'
import {ProductPrices} from '@festi/common/components/cart/ProductPrices'
import {PriceText} from '@festi/common/components/typography'
import settings from '@festi/common/constants/settings'
import {useCheckout} from '@festi/common/contexts'
import {getInsuranceText} from '@festi/common/utils/checkout'
import {sendEcommerceNullEvent} from '@festi/common/utils/tagmanager'
import {onDutyFree} from '@festi/common/constants/channels'
import {formatPrice} from '@festi/utils/numbers'
import CartItemWarning from '@festi/common/components/cart/CartItemWarning'
import {fluidRange} from '@festi/common/utils/styles'
import {CloseButton} from '@festi/common/components/buttons'
import {useCommonContext} from '@festi/common/contexts/common'

import ProductTags from '../utilities/ProductTags'
import NewQtyPicker from './NewQtyPicker'
import WebpImage from 'src/components/common/WebpImage'

interface Props {
  line: CheckoutLine
  onClick?: () => void
}

interface LineInsurancesProps {
  insurances: CheckoutLineInsurance[]
}

const ItemContainer = styled.div`
  position: relative;
  min-width: 280px;
  width: 100%;
  height: 100%;
  padding: 30px 24px 24px 24px;
  background-color: ${palette('white')};
  border-radius: 12px;
  transition: opacity 0.2s;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: 12px;
    pointer-events: none;
    z-index: 1;
  }

  &:hover:before {
    border-color: ${palette('ui20Solid')};
  }
`

const ItemInner = styled.div`
  display: flex;
`

const Remove = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

const Content = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fluidRange('width', '100px', '120px')};
  ${fluidRange('height', '100px', '120px')};
  padding-top: 12px;
`

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 28px;

  @media (max-width: 425px) {
    gap: 12px;
  }
`

const ItemInfoAnchor = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
  min-height: 54px;
  margin-top: 12px;
  gap: 5px;

  ${media.md`
    padding-right: 48px;
  `}
`

const Name = styled.div`
  font-size: 1rem;
  line-height: 22px;
  font-weight: 500;
  text-align: left;
  color: ${palette('blue')};
`

const Sku = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${palette('ui30Solid')};
`

const CountWithPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  width: 100%;
  gap: 4px;

  @media (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0;
  text-align: right;

  @media (max-width: 425px) {
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
  }
`

const LineText = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  color: ${palette('blue')};
  line-height: 1;

  &.noBreak {
    white-space: nowrap;
  }

  &:first-child {
    flex-grow: 1;
    text-align: left;
  }

  &:last-child {
    text-align: right;
    padding-left: 8px;
  }
`

const Line = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 8px;
`

export function LineInsurances({insurances}: LineInsurancesProps): JSX.Element {
  return (
    <Line>
      <LineText>
        {insurances?.length > 1 && `${insurances?.length} x `}
        {`${getInsuranceText(insurances?.[0])}`}
      </LineText>

      <PriceText size={fluidRange('font-size', '20px', '24px')}>
        {formatPrice(insurances?.reduce((sum, item) => sum + item?.price, 0))}
      </PriceText>
    </Line>
  )
}

export default function NewMiniCartItem({line, onClick}: Props): JSX.Element {
  const {cartState, cartUpdate} = useCheckout()
  const {handleCopyText} = useCommonContext()

  const [isRemoving, setIsRemoving] = useState<boolean>(false)

  const {variant, insurances, totalPrice, quantity} = line
  const {
    id,
    sku,
    name,
    product,
    firstImage,
    isInStockWeb,
    isInStockDutyfree,
    listings: {
      [settings.channel]: {
        price: {price},
      },
    },
  } = variant

  const linePrice = price * quantity

  const variantId = id
  const currentQty = cartState[variantId]

  const onClickRemove = useCallback(async () => {
    setIsRemoving(true)
    try {
      // GA4 add/remove from cart event
      sendEcommerceNullEvent()
      TagManager.dataLayer({
        dataLayer: {
          event: 'remove_from_cart',
          ecommerce: {
            currency: 'ISK',
            value: price * currentQty,
            items: [
              {
                item_id: sku,
                item_name: name,
                item_category: product.type,
                price: price,
                quantity: currentQty,
                elko_recommends: variant.recommended,
              },
            ],
          },
        },
        dataLayerName: 'dataLayer',
      })
      await cartUpdate({[variantId]: 0})
    } finally {
      setIsRemoving(false)
    }
  }, [
    sku,
    name,
    price,
    variantId,
    currentQty,
    product.type,
    variant.recommended,
    cartUpdate,
  ])

  const onUpdateQty = useCallback(
    (q: number) => {
      const adding = currentQty < q

      // GA4 add/remove from cart
      sendEcommerceNullEvent()
      TagManager.dataLayer({
        dataLayer: {
          event: adding ? 'add_to_cart' : 'remove_from_cart',
          ecommerce: {
            currency: 'ISK',
            value: price * quantity,
            items: [
              {
                item_id: sku,
                item_name: name,
                item_category: product.type,
                price: price,
                quantity: quantity,
                elko_recommends: variant.recommended,
                item_list_name: 'Karfa',
              },
            ],
          },
        },
        dataLayerName: 'dataLayer',
      })

      cartUpdate({[variantId]: q})
    },
    [
      sku,
      name,
      price,
      product,
      quantity,
      variantId,
      currentQty,
      variant.recommended,
      cartUpdate,
    ],
  )

  return (
    <ItemContainer
      style={isRemoving ? {opacity: 0.4, pointerEvents: 'none'} : {}}
    >
      <ProductTags variant={line.variant} />

      <ItemInner>
        <Remove>
          <CloseButton onClick={onClickRemove} />
        </Remove>

        <Content>
          <ImageWrapper>
            <WebpImage
              webp={firstImage?.image?.webpXs}
              jpg={firstImage?.image?.jpgXs}
              alt={firstImage?.alt || name}
            />
          </ImageWrapper>

          <ItemDetails>
            <ItemInfoAnchor
              href={`/vorur/${product?.slug}/${sku}`}
              onClick={onClick}
            >
              <Name>{name}</Name>

              <Sku onClick={(e) => handleCopyText(e, sku)}>{sku}</Sku>
            </ItemInfoAnchor>

            <CountWithPrice>
              <NewQtyPicker qty={currentQty} onUpdate={onUpdateQty} keepSmall />

              <PriceWrapper>
                <ProductPrices
                  size={fluidRange('font-size', '20px', '24px')}
                  oldPriceSize={fluidRange('font-size', '14px', '16px')}
                  price={linePrice}
                  lowestPrice={totalPrice}
                />
              </PriceWrapper>
            </CountWithPrice>
          </ItemDetails>
        </Content>
      </ItemInner>

      <CartItemWarning
        sku={sku}
        isInStock={onDutyFree ? isInStockDutyfree : isInStockWeb}
      />

      {!!insurances.length && <LineInsurances insurances={insurances} />}
    </ItemContainer>
  )
}
