import classNames from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import UserLineIcon from 'remixicon-react/UserLineIcon'
import Building4LineIcon from 'remixicon-react/Building4LineIcon'

import {H5, PSmall} from '@festi/common/components/typography'
import {useAuth} from '@festi/common/contexts'
import {styleScrollbar} from '@festi/common/styles'
import useBusiness from '@festi/common/utils/useBusiness'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 280px;
  overflow-y: auto;
  ${styleScrollbar}
`

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 16px 20px;
  border-radius: 8px;
  border: 1px solid ${palette('border')};
  background-color: ${palette('white')};
  cursor: pointer;

  ${PSmall} {
    color: ${palette('ui40Solid')};
  }

  .remixicon-icon {
    fill: ${palette('ui40Solid')};
  }

  &.selected {
    color: ${palette('link')};
    border: 2px solid ${palette('link')};
    cursor: not-allowed;

    ${H5},
    ${PSmall} {
      color: ${palette('link')};
    }

    .remixicon-icon {
      fill: ${palette('link')};
    }
  }
`

export default function CompanySelect() {
  const {user, businesses, selectedBusiness} = useAuth()
  const {handleBusinessSelect} = useBusiness()

  return (
    <Root>
      <SelectButton
        color="blue"
        className={classNames({selected: !selectedBusiness})}
        onClick={() => handleBusinessSelect('')}
      >
        <div>
          <UserLineIcon size={20} />
        </div>
        <div>
          <H5>{user.name}</H5>
          <PSmall>{user?.ssn}</PSmall>
        </div>
      </SelectButton>

      {businesses?.results?.map((b) => (
        <SelectButton
          key={b.id}
          className={classNames({selected: b.ssn === selectedBusiness})}
          onClick={() => handleBusinessSelect(b.ssn)}
        >
          <div>
            <Building4LineIcon size={20} />
          </div>
          <div>
            <H5>{b.name}</H5>
            <PSmall>{b?.ssn}</PSmall>
          </div>
        </SelectButton>
      ))}
    </Root>
  )
}
