import {useCallback} from 'react'

import {MutatorOptions} from 'swr'
import useSWR from 'swr/immutable'

import {
  restApi,
  Variant,
  VariantList,
  VariantDetail,
  ProductDetail,
  WishlistVariant,
  handleRestResponse,
} from '../api/rest'
import settings from '../constants/settings'
import {useAuth} from '../contexts'

import {getListingPrice} from '@festi/common/utils/price'
import {sendEcommerceAddToWishlistEvent} from '@festi/common/utils/tagmanager'

const wishlistCacheKey = 'all_wishlist_items'

export async function fetchWishlist() {
  return await handleRestResponse(
    restApi.wishlistItemsList({channel: settings.channel}),
  )
}

export function useWishlist() {
  const {user} = useAuth()

  const {
    data: wishlistItems,
    error,
    mutate,
    isValidating,
  } = useSWR(user ? wishlistCacheKey : null, fetchWishlist)

  const addVariantToWishlist = useCallback(
    (variant: VariantDetail | VariantList | WishlistVariant) => {
      // disable until we unify variant types
      /* const item: WishlistItem = {
      sku: variant?.sku || '',
      variant,
      wishlist: 1, // Change when using multiple wishlists
      dtCreated: formatISO(new Date()),
    } */

      mutate(
        async () => {
          await handleRestResponse(
            restApi.wishlistItemsCreate({
              sku: variant.sku || '',
              channel: settings.channel,
            }),
          )
          sendEcommerceAddToWishlistEvent(
            variant.name || '',
            variant.sku || '',
            getListingPrice(variant)?.price || 0,
            variant.product.type,
            variant.recommended,
          )
          return await fetchWishlist()
        },
        {
          // optimisticData: [item, ...(wishlistItems ?? [])],
          rollbackOnError: true,
          revalidate: false,
        },
      )
    },
    [mutate],
  )

  const addVariantToWishlistNew = useCallback(
    (
      product: ProductDetail,
      variant: Variant | VariantList | WishlistVariant,
    ) => {
      // disable until we unify variant types
      /* const item: WishlistItem = {
      sku: variant?.sku || '',
      variant,
      wishlist: 1, // Change when using multiple wishlists
      dtCreated: formatISO(new Date()),
    } */

      mutate(
        async () => {
          await handleRestResponse(
            restApi.wishlistItemsCreate({
              sku: variant.sku || '',
              channel: settings.channel,
            }),
          )
          sendEcommerceAddToWishlistEvent(
            variant.name || '',
            variant.sku || '',
            getListingPrice(variant)?.price || 0,
            product.type,
            variant.recommended,
          )
          return await fetchWishlist()
        },
        {
          // optimisticData: [item, ...(wishlistItems ?? [])],
          rollbackOnError: true,
          revalidate: false,
        },
      )
    },
    [mutate],
  )

  const removeVariantFromWishlist = useCallback(
    async (sku: string) => {
      const options: MutatorOptions = {
        optimisticData: wishlistItems?.filter((item) => item.sku !== sku),
        rollbackOnError: true,
        revalidate: false,
      }

      mutate(async () => {
        await restApi.wishlistItemsDestroy(sku)
        return await fetchWishlist()
      }, options)
    },
    [wishlistItems, mutate],
  )

  const removeAllFromWishlist = useCallback(async () => {
    const options: MutatorOptions = {
      optimisticData: [],
      rollbackOnError: true,
      revalidate: false,
    }

    mutate(async () => {
      await Promise.all(
        wishlistItems?.map((item) => restApi.wishlistItemsDestroy(item.sku)) ??
          [],
      )
      return await fetchWishlist()
    }, options)
  }, [wishlistItems, mutate])

  const isVariantInWishlist = useCallback(
    (sku: string) => {
      return wishlistItems?.some((i) => i.variant.sku === sku)
    },
    [wishlistItems],
  )

  const toggleVariantInWishlist = useCallback(
    (variant: VariantList | VariantDetail | WishlistVariant) => {
      isVariantInWishlist(variant?.sku || '')
        ? removeVariantFromWishlist(variant?.sku || '')
        : addVariantToWishlist(variant)
    },
    [isVariantInWishlist, removeVariantFromWishlist, addVariantToWishlist],
  )

  // TODO: Add a toast message when adding/removing from wishlist
  const toggleVariantInWishlistNew = useCallback(
    (
      product: ProductDetail,
      variant: Variant | VariantList | WishlistVariant,
    ) => {
      isVariantInWishlist(variant?.sku || '')
        ? removeVariantFromWishlist(variant?.sku || '')
        : addVariantToWishlistNew(product, variant)
    },
    [isVariantInWishlist, removeVariantFromWishlist, addVariantToWishlistNew],
  )

  return {
    error,
    isValidating,
    wishlistItems,
    isVariantInWishlist,
    addVariantToWishlist,
    addVariantToWishlistNew,
    toggleVariantInWishlist,
    toggleVariantInWishlistNew,
    removeVariantFromWishlist,
    removeAllFromWishlist,
  }
}
